















import { Vue } from 'nuxt-property-decorator'

const DefaultHeader = () => import('~/components/layout/Header.vue')
const FooterNav = () => import('~/components/layout/FooterNav.vue')
const MaxLimitModal = () => import('~/components/search/SearchMaxModal.vue')
const ConfirmModal = () => import('~/components/price/ConfirmModal.vue')
const UserProfileRegistration = () =>
  import('~/components/auth/UserProfileRegistration.vue')

export default Vue.extend({
  components: {
    DefaultHeader,
    FooterNav,
    UserProfileRegistration,
    ConfirmModal,
    MaxLimitModal,
  },
  data() {
    return {}
  },
  methods: {},
})
